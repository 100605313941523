/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react';
import { css, jsx } from '@emotion/react';

const footerStyle = css({
  height: '64px',
});

export default class Footer extends React.Component {
  render () {
    return (
      <div css={footerStyle}></div>
    );
  }
}
