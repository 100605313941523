/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react';
import { css, jsx } from '@emotion/react';

const shopPage = css({
  width: '100%',
  height: 'calc(100vh - 64px)',
  position: 'relative',
  backgroundColor: '#202020',
  color: '#8a0707',
  fontFamily: 'Ink Free, sans-serif',
  overflowY: 'scroll',
  overflowX: 'hidden',
});
const headerStyle = css({
  fontSize: '3em',
  fontWeight: 'bold',
});
const bodyStyle = css({
  width: '50%',
  fontSize: '2em',
});

export default class Shop extends React.Component {
  render () {
    let js = document.createElement("script");
    js.type = "text/javascript";
    js.src = "https://shadowarmada98.myspreadshop.com/shopfiles/shopclient/shopclient.nocache.js";
    document.body.appendChild(js);

    return (
      <div id="shop" css={shopPage}>
        <div id="myShop">
          <div>Merch Store</div>
        </div>
      </div>
    );
  }
}
