/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react';
import { css, jsx } from '@emotion/react';

const gamesPage = css({
  width: '100%',
  height: '100vh',
  backgroundColor: '#202020',
  color: '#8a0707',
  fontFamily: 'Ink Free, sans-serif',
  overflowY: 'scroll',
  overflowX: 'hidden',
});
const headerStyle = css({
  fontSize: '3em',
  fontWeight: 'bold',
});
const bodyStyle = css({
  width: '50%',
  fontSize: '2em',
});
const textBlock = css({
  alignItems: 'center',
  display: 'flex',
  margin: '10px 0px',
  flexDirection: 'column',
  fontSize: '3em',
});

export default class Games extends React.Component {
  render () {
    return (
      <div id="games" css={gamesPage}>
        <div css={textBlock}>
          <p>Coming Soon!</p>
        </div>
      </div>
    );
  }
}
