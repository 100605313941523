/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { css, jsx } from '@emotion/react';

import './App.css';
import Header from './Header';
import Home from './Home';
import Shop from './Shop';
import Games from './Games';
import GameStats from './GameStats';

const blockHeader = css({
  height: '64px',
});

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <Header />
        <div css={blockHeader}></div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/games" element={<Games />} />
          <Route path="/game-stats" element={<GameStats />} />
        </Routes>
      </Router>
    );

  }
}
